<template>
  <div class="home-container" v-if="isAccountOwner || isTeacher">
    <GuruBreadcrumb :link-list="linkList"/>
    <div class="columns">
      <div class="column is-6 is-offset-3">
        <nav class="panel">
          <div class="panel-heading has-text-weight-bold has-text-grey-darker panel-heading-with-action">
            <span>Assignment</span>
          </div>
          <div class="panel-block guru-main-block">
            <div><span class="has-text-weight-semibold"> Title:</span>&nbsp;&nbsp;{{assignment.title}}</div>
            <div><span class="has-text-weight-semibold"> Status:</span>&nbsp;&nbsp;{{assignment.status}}</div>
            <div class="has-text-weight-semibold">Description:&nbsp;&nbsp;</div>
            <div class="white-space-pre" v-html="assignment.description"></div>
            <div class="columns is-marginless">
              <div class="column is-6"><span class="has-text-weight-semibold"> Default Language:</span>&nbsp;&nbsp;{{assignment.defaultLanguage.displayName}}</div>
              <div class="column is-6" v-if="assignment.defaultLanguage.versions && assignment.defaultLanguage.versions.length > 0"><span class="has-text-weight-semibold"> Version:</span>&nbsp;&nbsp;{{assignment.defaultLanguage.versions[assignment.versionIndex]}}</div>
            </div>
            <div class="columns is-marginless">
              <div class="column is-6"><span class="has-text-weight-semibold"> Auto Correct:</span>&nbsp;&nbsp;{{assignment.autoCorrect}}</div>
              <div class="column is-6" v-if="assignment.autoCorrect"><span class="has-text-weight-semibold"> Auto Score:</span>&nbsp;&nbsp;{{assignment.autoScore}}</div>
            </div>
            <div v-if="assignment.autoScore"><span class="has-text-weight-semibold"> Negative Marking:</span>&nbsp;&nbsp;{{assignment.enableNegativeMark}}</div>
            <div class="columns is-marginless">
              <div class="column is-6" v-if="assignment.resultType"><span class="has-text-weight-semibold"> Result Type:</span>&nbsp;&nbsp;{{assignment.resultType.type}}</div>
              <div class="column is-6" v-if="assignment.autoCorrect && assignment.disclose"><span class="has-text-weight-semibold"> Disclose Result:</span>&nbsp;&nbsp;{{assignment.disclose.option}}</div>
            </div>
            <div class="has-text-centered">
              <span v-if="isInProgressAssignment || isReadyAssignment"><button class="button has-text-weight-semibold" @click="putOnHold">Put On Hold</button>&nbsp;</span>
              <span v-if="isDraftAssignment || isOnHoldAssignment"><button class="button is-info has-text-white has-text-weight-semibold" @click="activateAssignment">Activate</button>&nbsp;</span>
              <span v-if="isReadyAssignment || isInProgressAssignment || isCompletedAssignment"><button class="button has-text-weight-semibold" @click="changeAssignmentDuration">Change Duration</button>&nbsp;</span>
              <button class="button has-text-weight-semibold" @click="preview">Preview</button>&nbsp;
              <button class="button has-text-weight-semibold" @click="editAssignment">Edit</button>&nbsp;
              <div class="dropdown is-marginless is-paddingless is-hoverable">
                <div class="dropdown-trigger is-marginless is-paddingless">
                  <button class="button has-text-weight-semibold" aria-haspopup="true" aria-controls="dropdown-menu3">
                    <span>More...</span>
                    <span class="icon is-small">
                      <font-awesome-icon icon="chevron-down"/>
                    </span>
                  </button>
                </div>
                <div class="dropdown-menu" id="dropdown-menu3" role="menu">
                  <div class="dropdown-content has-text-weight-semibold">
                    <a class="dropdown-item" @click="cloneAssignment">
                      <font-awesome-icon icon="clone"/>&nbsp;
                      Clone
                    </a>
                    <!--<a href="#" class="dropdown-item has-text-weight-semibold">
                      <font-awesome-icon icon="university"/>&nbsp;
                      Add to Institute Library
                    </a>-->
                    <a class="dropdown-item has-text-weight-semibold" @click="showDeleteAssignment">
                      <font-awesome-icon icon="trash-alt"/>&nbsp;
                      Delete
                    </a> &nbsp;&nbsp;
                  </div>
                </div>
              </div>
            </div>
            <div class="has-text-centered">
              <router-link class="button" :to="assignmentsHome">Close</router-link>
            </div>
          </div>
        </nav>
      </div>
    </div>
    <div class="table-section margin-top-20px">
      <div class="columns">
        <div class="column is-6">
          <nav class="panel">
            <div class="panel-heading has-text-weight-bold has-text-grey-darker panel-heading-with-action">
              <span>Questions</span>
              <button class="button is-success has-text-white has-text-weight-semibold is-pulled-right panel-button" @click="addQuestion">Add Question</button>
            </div>
            <div class="panel-block has-text-centered is-paddingless">
              <table class="table is-bordered is-fullwidth">
                <thead>
                <tr>
                  <th class="has-text-centered">Question</th>
                  <th class="has-text-centered">Type</th>
                  <th></th>
                </tr>
                </thead>
                <draggable :list="assignment.questions" class="list-group"
                           ghost-class="ghost" tag="tbody" @change="reOrderQuestions">
                  <tr v-for="(question, index) in assignment.questions" v-bind:key="index">
                    <td><a @click="editQuestion(question)">{{index+1}}. {{question.question}}</a></td>
                    <td class="has-text-centered">{{question.questionType}}</td>
                    <td class="has-text-centered"><a @click="removeQuestion(question)"><font-awesome-icon icon="trash-alt"></font-awesome-icon></a></td>
                  </tr>
                </draggable>
              </table>
            </div>
            <div class="reorder-hint">Hints: Drag and drop the questions to reorder</div>
          </nav>
        </div>
        <div class="column is-6">
          <nav class="panel">
            <div class="panel-heading has-text-weight-bold has-text-grey-darker panel-heading-with-action">
              <span>Assignment Lead</span>
            </div>
            <div class="panel-block">
              {{assignment.ownedBy.firstName}} {{assignment.ownedBy.lastName}}
            </div>
          </nav>
          <nav class="panel">
            <div class="panel-heading has-text-weight-bold has-text-grey-darker panel-heading-with-action">
              <span>Teacher(s)</span>
              <button class="button is-success has-text-white has-text-weight-semibold is-pulled-right panel-button" @click="addCoOwner">Add Teacher</button>
            </div>
            <div class="panel-block has-text-centered is-paddingless">
              <table class="table is-bordered is-fullwidth">
                <thead>
                <tr>
                  <th class="has-text-centered">Teacher Name</th>
                  <th class="has-text-centered">Email</th>
                  <th></th>
                </tr>
                </thead>
                <tbody>
                <tr v-for="coOwner in assignment.coOwners" v-bind:key="coOwner.email">
                  <td class="has-text-centered">{{coOwner.firstName}} {{coOwner.lastName}}</td>
                  <td class="has-text-centered">{{coOwner.email}}</td>
                  <td class="has-text-centered"><a @click="removeCoOwner(coOwner)"><font-awesome-icon icon="trash-alt"></font-awesome-icon></a></td>
                </tr>
                </tbody>
              </table>
            </div>
          </nav>
          <nav class="panel">
            <div class="panel-heading has-text-weight-bold has-text-grey-darker panel-heading-with-action">
              <span>Students</span>
              <div class="dropdown is-marginless is-paddingless is-hoverable is-right is-pulled-right">
                <div class="dropdown-trigger is-marginless is-paddingless">
                  <button class="button is-success has-text-white has-text-weight-semibold panel-button" aria-haspopup="true" aria-controls="dropdown-menu3">
                    <span>Add</span>
                    <span class="icon is-small">
                      <font-awesome-icon icon="chevron-down"/>
                    </span>
                  </button>
                </div>
                <div class="dropdown-menu" id="dropdown-menu4" role="menu">
                  <div class="dropdown-content has-text-weight-semibold">
                    <a href="#" class="dropdown-item" @click="addStudent">
                      Add Student
                    </a>
                    <a href="#" class="dropdown-item has-text-weight-semibold" @click="addStudentGroup">
                      Import from Student Group
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div class="panel-block has-text-centered is-paddingless">
              <table class="table is-bordered is-fullwidth">
                <thead>
                <tr>
                  <th class="has-text-centered">Student Name</th>
                  <th class="has-text-centered">Email</th>
                  <th class="has-text-centered">Status</th>
                  <th></th>
                </tr>
                </thead>
                <tbody>
                <tr v-for="student in assignment.students" v-bind:key="student.email">
                  <td class="has-text-centered">{{student.firstName}} {{student.lastName}}</td>
                  <td class="has-text-centered">{{student.email}}</td>
                  <td class="has-text-centered">{{student.status}}</td>
                  <td class="has-text-centered">
                    <a @click="evaluate(student)" ><font-awesome-icon icon="sign-in-alt"></font-awesome-icon></a>&nbsp;&nbsp;&nbsp;
                    <a @click="removeStudent(student)" ><font-awesome-icon icon="trash-alt"></font-awesome-icon></a>
                  </td>
                </tr>
                </tbody>
              </table>
            </div>
          </nav>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import formMixin from '../../../assets/javascript/form-mixin'
import draggable from 'vuedraggable'
import langListMixin from '../../../assets/javascript/language-list-mixin'
import { eventBus } from '../../../assets/javascript/event-bus'
import GuruBreadcrumb from '../GuruBreadcrumb'
import { GURU_CONSTANTS, GURU_EVENTS } from '../../../assets/javascript/constants'

export default {
  name: 'guruAssignment',
  components: { GuruBreadcrumb, draggable },
  props: ['currentAssociation', 'isAccountOwner', 'isInstituteAdmin', 'isTeacher', 'isStudent', 'guruInitialised'],
  inject: ['$validator'],
  mixins: [formMixin, langListMixin],
  data: function () {
    return {
      assignment: {
        insAssignmentId: '',
        name: '',
        status: null,
        description: '',
        students: [],
        coOwners: [],
        questions: [],
        defaultLanguage: {},
        autoCorrect: false,
        autoScore: false,
        enableNegativeMark: false,
        disclose: null,
        resultType: null,
        ownedBy: {}
      },
      linkList: [
        {
          title: 'Guru Home',
          href: '/guru'
        },
        {
          title: 'Institute Home',
          href: '/guru/ins/' + this.$route.params.instituteCode
        },
        {
          title: 'Assignments',
          href: '/guru/ins/' + this.$route.params.instituteCode + '/assignments'
        }
      ]
    }
  },
  mounted () {
    eventBus.$on(GURU_EVENTS.ADD_STUDENT_GROUP_TO_ASSIGNMENT, (sg) => {
      this.addStudentGroupToAssignment(sg)
    })

    eventBus.$on(GURU_EVENTS.ADD_STUDENT_TO_ASSIGNEMNT, (uzr) => {
      this.addRemoveUserFromAssignment(uzr, 'student', 'add')
    })

    eventBus.$on(GURU_EVENTS.ADD_TEACHER_TO_ASSIGNMENT, (uzr) => {
      this.addRemoveUserFromAssignment(uzr, 'staff-active', 'add')
    })

    eventBus.$on(GURU_EVENTS.REMOVE_STUDENT_FROM_ASSIGNMENT, (uzr) => {
      this.addRemoveUserFromAssignment(uzr, 'student', 'remove')
    })

    eventBus.$on(GURU_EVENTS.REMOVE_TEACHER_FROM_ASSIGNMENT, (uzr) => {
      this.addRemoveUserFromAssignment(uzr, 'staff', 'remove')
    })

    eventBus.$on(GURU_EVENTS.UPDATE_IN_ASSIGNMENT, (assignment) => {
      this.setAssignment(assignment)
    })

    eventBus.$on(GURU_EVENTS.DELETE_ASSIGNMENT, () => {
      this.deleteAssignment()
    })

    eventBus.$on(GURU_EVENTS.ADD_QUESTION_TO_ASSIGNEMNT, (question) => {
      this.addQuestionToAssignment(question)
    })

    eventBus.$on(GURU_EVENTS.UPDATE_QUESTION_IN_ASSIGNMENT, (question) => {
      this.updateQuestionInAssignment(question)
    })

    eventBus.$on(GURU_EVENTS.DELETE_QUESTION_IN_ASSIGNMENT, (question) => {
      this.deleteQuestion(question)
    })

    eventBus.$on(GURU_EVENTS.ON_HOLD_ASSIGNMENT, () => {
      this.onHoldAssignment()
    })

    this.getAssignment()
  },
  beforeDestroy () {
    eventBus.$off(GURU_EVENTS.ADD_STUDENT_GROUP_TO_ASSIGNMENT)
    eventBus.$off(GURU_EVENTS.ADD_STUDENT_TO_ASSIGNEMNT)
    eventBus.$off(GURU_EVENTS.ADD_TEACHER_TO_ASSIGNMENT)
    eventBus.$off(GURU_EVENTS.REMOVE_STUDENT_FROM_ASSIGNMENT)
    eventBus.$off(GURU_EVENTS.REMOVE_TEACHER_FROM_ASSIGNMENT)
    eventBus.$off(GURU_EVENTS.UPDATE_IN_ASSIGNMENT)
    eventBus.$off(GURU_EVENTS.DELETE_ASSIGNMENT)
    eventBus.$off(GURU_EVENTS.ADD_QUESTION_TO_ASSIGNEMNT)
    eventBus.$off(GURU_EVENTS.UPDATE_QUESTION_IN_ASSIGNMENT)
    eventBus.$off(GURU_EVENTS.DELETE_QUESTION_IN_ASSIGNMENT)
    eventBus.$off(GURU_EVENTS.ON_HOLD_ASSIGNMENT)
  },
  methods: {
    getAssignment () {
      this.doodleForm.errorMessage = ''
      this.executeAPI({
        url: '/api/guru-api/assignment/getAssignment',
        data: {
          instituteCode: this.$route.params.instituteCode,
          assignmentId: this.$route.params.insAssignmentId
        },
        method: 'post',
        markCompleted: false,
        form: this.doodleForm,
        jdaCategory: window.jda.CATEGORY.GURU,
        successAction: (data) => {
          this.setAssignment(data.assignment)
        },
        jdaEvent: 'guru-get-assignment',
        jdaLabel: ''
      })
    },
    setAssignment (assignment) {
      assignment.defaultLanguage = this.getLanguageByCode(assignment.defaultLanguage)
      assignment.resultType = GURU_CONSTANTS.RESULT_TYPES[assignment.resultType]
      assignment.disclose = GURU_CONSTANTS.DISCLOSE_OPTIONS[assignment.disclose]
      Object.assign(this.assignment, assignment)
    },
    editAssignment () {
      eventBus.$emit(GURU_EVENTS.SHOW_IN_SIDEBAR, () => import('./GuruAddEditAssignment.vue'),
        { mode: 'edit', instituteCode: this.$route.params.instituteCode, initData: this.assignment })
    },
    cloneAssignment () {
      eventBus.$emit(GURU_EVENTS.SHOW_IN_SIDEBAR, () => import('./GuruAddEditAssignment.vue'),
        { mode: 'clone', instituteCode: this.$route.params.instituteCode, initData: this.assignment })
    },
    activateAssignment () {
      eventBus.$emit(GURU_EVENTS.SHOW_IN_SIDEBAR, () => import('./GuruActivateAssignment.vue'),
        { instituteCode: this.$route.params.instituteCode, initData: this.assignment, mode: 'activate' })
    },
    changeAssignmentDuration () {
      eventBus.$emit(GURU_EVENTS.SHOW_IN_SIDEBAR, () => import('./GuruActivateAssignment.vue'),
        { instituteCode: this.$route.params.instituteCode, initData: this.assignment, mode: 'change' })
    },
    deleteAssignment () {
      this.doodleForm.errorMessage = ''
      this.executeAPI({
        url: '/api/guru-api/assignment/deleteAssignment',
        data: {
          instituteCode: this.$route.params.instituteCode,
          assignmentId: this.$route.params.insAssignmentId
        },
        method: 'post',
        markCompleted: true,
        form: this.doodleForm,
        jdaCategory: window.jda.CATEGORY.GURU,
        successAction: (data) => {
          eventBus.$emit(GURU_EVENTS.CONFIRMATION_ACTION_SUCCESS_NOTIFICATION)
          window._.delay(() => { this.$router.push('/guru/ins/' + this.$route.params.instituteCode + '/assignments') }, 1500)
        },
        failureAction: () => {
          eventBus.$emit(GURU_EVENTS.CONFIRMATION_ACTION_FAILED_NOTIFICATION)
        },
        jdaEvent: 'guru-delete-student-group',
        jdaLabel: ''
      })
    },
    showDeleteAssignment () {
      eventBus.$emit(GURU_EVENTS.SHOW_IN_SIDEBAR, () => import('../../Utils/ConfirmationSideBar.vue'),
        {
          'confirmationText': 'Are you sure, do you want to remove this assignment?',
          'yesText': 'Delete',
          'noText': 'Close',
          'notifyEvent': GURU_EVENTS.DELETE_ASSIGNMENT,
          'inProgressMessage': 'JDoodle is deleting the assignment, Please wait...',
          'successMessage': 'Assignment Deleted.',
          'failureMessage': 'Unable to delete the assignment. Please try again.'
        })
    },
    putOnHold () {
      eventBus.$emit(GURU_EVENTS.SHOW_IN_SIDEBAR, () => import('../../Utils/ConfirmationSideBar.vue'),
        {
          'confirmationText': 'Are you sure, do you want to put this assignment on hold?',
          'yesText': 'Yes',
          'noText': 'No',
          'notifyEvent': GURU_EVENTS.ON_HOLD_ASSIGNMENT,
          'inProgressMessage': 'JDoodle is updating the assignment, Please wait...',
          'successMessage': 'Assignment Status Updated.',
          'failureMessage': 'Unable to update the assignment. Please try again.'
        })
    },
    addStudentGroup () {
      eventBus.$emit(GURU_EVENTS.SHOW_IN_SIDEBAR, () => import('../GuruIncludeStudentGroupInX.vue'),
        {
          'formTitle': 'Add Student Group',
          'inProgressMessage': 'Adding student group to the assignment, Please wait...',
          'successMessage': 'Student Group Added Successfully',
          'actionText': 'Add Student From Group',
          'notifyEvent': GURU_EVENTS.ADD_STUDENT_GROUP_TO_ASSIGNMENT
        })
    },
    addStudent () {
      eventBus.$emit(GURU_EVENTS.SHOW_IN_SIDEBAR, () => import('../GuruIncludeUserInX.vue'),
        {
          'formTitle': 'Add Student',
          'inProgressMessage': 'Adding student to the assignment, Please wait...',
          'successMessage': 'Student Added Successfully',
          'actionText': 'Add Student',
          'existingUsers': this.assignment.students,
          'userFilter': 'student-active',
          'notifyEvent': GURU_EVENTS.ADD_STUDENT_TO_ASSIGNEMNT
        })
    },
    addCoOwner () {
      eventBus.$emit(GURU_EVENTS.SHOW_IN_SIDEBAR, () => import('../GuruIncludeUserInX.vue'),
        {
          'formTitle': 'Add Teacher to Assignment',
          'inProgressMessage': 'Adding teacher to the assignment, Please wait...',
          'successMessage': 'Teacher Added Successfully',
          'actionText': 'Add Teacher',
          'existingUsers': this.assignment.coOwners,
          'userFilter': 'teacher-active',
          'notifyEvent': GURU_EVENTS.ADD_TEACHER_TO_ASSIGNMENT
        })
    },
    removeStudent (uzr) {
      eventBus.$emit(GURU_EVENTS.SHOW_IN_SIDEBAR, () => import('../../Utils/ConfirmationSideBar.vue'),
        {
          'confirmationText': 'Are you sure, do you want to remove this student from the group?',
          'yesText': 'Delete',
          'noText': 'Close',
          'notifyEvent': GURU_EVENTS.REMOVE_STUDENT_FROM_ASSIGNMENT,
          'inProgressMessage': 'JDoodle removing the user from the group, Please wait...',
          'successMessage': 'User removed from the group.',
          'failureMessage': 'Unable to remove the user from the group. Please try again.',
          'refData': uzr
        })
    },
    removeCoOwner (uzr) {
      eventBus.$emit(GURU_EVENTS.SHOW_IN_SIDEBAR, () => import('../../Utils/ConfirmationSideBar.vue'),
        {
          'confirmationText': 'Are you sure, do you want to remove this teacher from the assignment?',
          'yesText': 'Delete',
          'noText': 'Close',
          'notifyEvent': GURU_EVENTS.REMOVE_TEACHER_FROM_ASSIGNMENT,
          'inProgressMessage': 'JDoodle removing the teacher from the assignment, Please wait...',
          'successMessage': 'Teacher removed from the assignment.',
          'failureMessage': 'Unable to remove the teacher from the assignment. Please try again.',
          'refData': uzr
        })
    },
    addStudentGroupToAssignment (st) {
      this.executeAPIWitoutValiation({
        url: '/api/guru-api/assignment/updateAssignmentParticipants',
        data: {
          instituteCode: this.$route.params.instituteCode,
          insAssignmentId: this.$route.params.insAssignmentId,
          email: st.insStudentGroupId,
          participantType: 'PvtStudentFromGroup',
          updateType: 'add'
        },
        method: 'post',
        markCompleted: false,
        form: {},
        jdaCategory: window.jda.CATEGORY.GURU,
        successAction: (data) => {
          this.assignment.students.push(...st.students)
          this.assignment.students = window._.uniqBy(this.assignment.students, (o) => { return o.email })
          this.assignment.students = window._.sortBy(this.assignment.students, (o) => { return o.email })
          eventBus.$emit(GURU_EVENTS.STUDENT_GROUP_INCLUDED_NOTIFICATION, st)
        },
        failureAction: () => {
          eventBus.$emit(GURU_EVENTS.STUDENT_GROUP_INCLUDE_FAILED_NOTIFICATION)
        },
        jdaEvent: 'guru-add-student-group-to-assignment',
        jdaLabel: ''
      })
    },
    addRemoveUserFromAssignment (uzr, type, action) {
      this.executeAPIWitoutValiation({
        url: '/api/guru-api/assignment/updateAssignmentParticipants',
        data: {
          instituteCode: this.$route.params.instituteCode,
          insAssignmentId: this.$route.params.insAssignmentId,
          email: uzr.email,
          participantType: type,
          updateType: action
        },
        method: 'post',
        markCompleted: false,
        form: {},
        jdaCategory: window.jda.CATEGORY.GURU,
        successAction: (data) => {
          if (type === 'student' && action === 'add') {
            this.assignment.students.push(uzr)
            this.assignment.students = window._.sortBy(this.assignment.students, (o) => { return o.email })
          } else if (type === 'staff-active' && action === 'add') {
            this.assignment.coOwners.push(uzr)
            this.assignment.coOwners = window._.sortBy(this.assignment.coOwners, (o) => { return o.email })
          } else if (type === 'student' && action === 'remove') {
            this.assignment.students.splice(this.assignment.students.indexOf(uzr), 1)
          } else if (type === 'staff' && action === 'remove') {
            this.assignment.coOwners.splice(this.assignment.coOwners.indexOf(uzr), 1)
          }

          if (action === 'add') {
            eventBus.$emit(GURU_EVENTS.USER_INCLUDED_NOTIFICATION, uzr)
          } else if (action === 'remove') {
            eventBus.$emit(GURU_EVENTS.CONFIRMATION_ACTION_SUCCESS_NOTIFICATION)
          }
        },
        failureAction: () => {
          if (action === 'add') {
            eventBus.$emit(GURU_EVENTS.USER_INCLUDE_FAILED_NOTIFICATION)
          } else if (action === 'remove') {
            eventBus.$emit(GURU_EVENTS.CONFIRMATION_ACTION_FAILED_NOTIFICATION)
          }
        },
        jdaEvent: (action === 'add') ? 'guru-add-user-to-assignment' : 'guru-delete-user-from-assignment',
        jdaLabel: ''
      })
    },
    addQuestion () {
      eventBus.$emit(GURU_EVENTS.SHOW_IN_SIDEBAR, () => import('./GuruAddEditQuestion.vue'),
        {
          mode: 'add',
          instituteCode: this.$route.params.instituteCode,
          autoCorrect: this.assignment.autoCorrect,
          autoScore: this.assignment.autoScore,
          enableNegativeMark: this.assignment.enableNegativeMark,
          disclose: this.assignment.disclose,
          insAssignmentId: this.assignment.insAssignmentId,
          defaultLanguage: this.assignment.defaultLanguage,
          versionIndex: this.assignment.versionIndex
        })
    },
    editQuestion (question) {
      eventBus.$emit(GURU_EVENTS.SHOW_IN_SIDEBAR, () => import('./GuruAddEditQuestion.vue'),
        {
          mode: 'edit',
          currentAssociation: this.currentAssociation,
          assignment: this.assignment,
          instituteCode: this.$route.params.instituteCode,
          autoCorrect: this.assignment.autoCorrect,
          autoScore: this.assignment.autoScore,
          enableNegativeMark: this.assignment.enableNegativeMark,
          disclose: this.assignment.disclose,
          insAssignmentId: this.assignment.insAssignmentId,
          assQuestionId: question.id
        })
    },
    removeQuestion (question) {
      eventBus.$emit(GURU_EVENTS.SHOW_IN_SIDEBAR, () => import('../../Utils/ConfirmationSideBar.vue'),
        {
          'confirmationText': 'Are you sure, do you want to remove this question?',
          'yesText': 'Delete',
          'noText': 'Close',
          'notifyEvent': GURU_EVENTS.DELETE_QUESTION_IN_ASSIGNMENT,
          'inProgressMessage': 'JDoodle deleting the question, Please wait...',
          'successMessage': 'Question deleted.',
          'failureMessage': 'Unable to delete the question. Please try again.',
          'refData': question
        })
    },
    addQuestionToAssignment (question) {
      this.assignment.questions.push(question)
    },
    updateQuestionInAssignment (question) {
      let indexOfQuestion = this.assignment.questions.findIndex((o) => { return question.id === o.id })
      this.assignment.questions.splice(indexOfQuestion, 1, question)
    },
    deleteQuestion (question) {
      this.doodleForm.errorMessage = ''
      this.executeAPI({
        url: '/api/guru-api/assignment/deleteQuestion',
        data: {
          instituteCode: this.$route.params.instituteCode,
          assignmentId: this.assignment.insAssignmentId,
          questionId: question.id
        },
        method: 'post',
        markCompleted: true,
        form: this.doodleForm,
        jdaCategory: window.jda.CATEGORY.GURU,
        successAction: (data) => {
          eventBus.$emit(GURU_EVENTS.CONFIRMATION_ACTION_SUCCESS_NOTIFICATION)
          this.assignment.questions.splice(this.assignment.questions.indexOf(question), 1)
        },
        failureAction: () => {
          eventBus.$emit(GURU_EVENTS.CONFIRMATION_ACTION_FAILED_NOTIFICATION)
        },
        jdaEvent: 'guru-delete-question',
        jdaLabel: ''
      })
    },
    onHoldAssignment () {
      this.doodleForm.errorMessage = ''
      this.executeAPI({
        url: '/api/guru-api/assignment/onHoldAssignment',
        data: {
          instituteCode: this.$route.params.instituteCode,
          assignmentId: this.$route.params.insAssignmentId
        },
        method: 'post',
        markCompleted: true,
        form: this.doodleForm,
        jdaCategory: window.jda.CATEGORY.GURU,
        successAction: (data) => {
          eventBus.$emit(GURU_EVENTS.CONFIRMATION_ACTION_SUCCESS_NOTIFICATION)
          this.setAssignment(data.assignment)
        },
        failureAction: () => {
          eventBus.$emit(GURU_EVENTS.CONFIRMATION_ACTION_FAILED_NOTIFICATION)
        },
        jdaEvent: 'guru-on-hold-assignment',
        jdaLabel: ''
      })
    },
    preview () {
      this.$router.push('/guru/ins/' + this.$route.params.instituteCode + '/assignment/' + this.$route.params.insAssignmentId + '/preview')
    },
    evaluate (student) {
      if (!this.readToEvaluate(student)) {
        eventBus.$emit(GURU_EVENTS.SHOW_IN_SIDEBAR, () => import('../../Utils/MessageSideBar.vue'),
          {
            message: 'Assignment not ready for evaluation yet.',
            messageClass: 'has-text-danger'
          })
        return
      }
      this.$store.commit('studentUnderEvaluation', student.email)
      this.$router.push('/guru/ins/' + this.$route.params.instituteCode + '/assignment/' + this.$route.params.insAssignmentId + '/evaluate')
    },
    readToEvaluate (student) {
      return student.status !== GURU_CONSTANTS.ASSIGNMENT_STUDENT_STATUS.YET_TO_START && student.status !== GURU_CONSTANTS.ASSIGNMENT_STUDENT_STATUS.IN_PROGRESS
    },
    reOrderQuestions () {
      this.doodleForm.errorMessage = ''
      this.executeAPIWitoutValiation({
        url: '/api/guru-api/assignment/reOrderQuestions',
        data: JSON.stringify({
          instituteCode: this.$route.params.instituteCode,
          assignmentId: this.$route.params.insAssignmentId,
          positions: window._.map(this.assignment.questions, 'id')
        }),
        contentType: 'application/json',
        method: 'post',
        form: this.doodleForm,
        jdaCategory: window.jda.CATEGORY.GURU,
        jdaEvent: 'guru-reorder-assignment-questions',
        jdaLabel: ''
      })
    }
  },
  computed: {
    assignmentsHome () {
      return this.insHome + '/assignments'
    },
    insHome () {
      return '/guru/ins/' + this.$route.params.instituteCode
    },
    isDraftAssignment () {
      return this.assignment.status === GURU_CONSTANTS.ASSIGNMENT_STATUS.DRAFT
    },
    isInProgressAssignment () {
      return this.assignment.status === GURU_CONSTANTS.ASSIGNMENT_STATUS.IN_PROGRESS
    },
    isOnHoldAssignment () {
      return this.assignment.status === GURU_CONSTANTS.ASSIGNMENT_STATUS.ON_HOLD
    },
    isReadyAssignment () {
      return this.assignment.status === GURU_CONSTANTS.ASSIGNMENT_STATUS.READY
    },
    isCompletedAssignment () {
      return this.assignment.status === GURU_CONSTANTS.ASSIGNMENT_STATUS.COMPLETED
    }
  }
}
</script>

<style scoped lang="scss">
  .guru-ins-title {
    font-size: 1.2em;
    color: rgb(88, 88, 90);
    font-weight: bold;
    margin-bottom: 20px;
  }

  .table-section {
    padding: 2%;
  }

  .panel-heading-with-action {
    padding: 0.75em;
  }

  .panel-button {
    margin-top: -5px;
    border-width: 0px;
    font-size: 0.85em;
  }

  .ghost {
    opacity: 0.5;
    background: #c8ebfb;
  }

  .reorder-hint {
    font-size: 0.8em;
  }
</style>
